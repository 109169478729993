@import './variables.scss';

.MuiPaper-root {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid $stroke2;
  background: $widget-bg;
  border-color: $stroke2;
  box-shadow: none;
}
