@import '../../styles/variables.scss';

.sub-menu {
  max-width: 1230px;
  display: flex;
  align-items: center;
  padding: 24px 0;

  &-item {
    padding: 8px 20px 10px;
    font-weight: 500;
    line-height: 24px;
    color: $neutral-gray-500;
    text-decoration: none;
    border-bottom: 2px solid $neutral-gray-200;

    &.active {
      color: $primary;
      border-bottom: 2px solid $primary;
    }
  }
}
