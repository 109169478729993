.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin: auto;
    padding: 32px;
    inset: 0;
    width: 640px;

    &-icon {
        display: block;
        width: 32px;
        height: 32px;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 0C24.8225 0 32 7.17753 32 16C32 24.8225 24.8225 32 16 32C7.17755 32 0 24.8225 0 16C-8.7738e-05 7.17753 7.17746 0 16 0ZM26.9566 10.5506C27.1415 9.84201 26.717 9.11768 26.0084 8.93275C25.4248 8.78053 24.831 9.04236 24.5362 9.53527C23.872 8.47273 23.015 7.52864 22.0097 6.77267C20.2335 5.43689 18.1169 4.73077 15.889 4.73077C13.6611 4.73077 11.5445 5.4368 9.76822 6.77267C8.05145 8.06371 6.76243 9.90097 6.13869 11.946C5.92504 12.6464 6.31973 13.3875 7.0202 13.6011C7.14908 13.6404 7.27938 13.6591 7.40747 13.6591C7.97551 13.6591 8.50095 13.2912 8.67527 12.7196C9.64888 9.52749 12.5478 7.3827 15.8889 7.3827C18.3532 7.3827 20.5759 8.55043 21.964 10.4436C21.4127 10.364 20.849 10.639 20.5874 11.1649C20.2613 11.8205 20.5284 12.6165 21.184 12.9425L23.8835 14.2854C24.1269 14.4064 24.3886 14.4672 24.6488 14.4672C24.8836 14.4672 25.1173 14.4178 25.3353 14.3186C25.7951 14.1095 26.1337 13.7026 26.2643 13.2024L26.9566 10.5506ZM6.00327 23.0673C6.11553 23.0965 6.22815 23.1106 6.339 23.1106C6.80459 23.1106 7.2373 22.8629 7.47536 22.4647C8.13958 23.5273 8.9966 24.4714 10.0019 25.2274C11.7781 26.5632 13.8946 27.2693 16.1226 27.2693C18.3506 27.2693 20.4671 26.5633 22.2434 25.2274C23.9601 23.9365 25.2491 22.0991 25.8729 20.0541C26.0865 19.3537 25.6918 18.6126 24.9914 18.399C24.2907 18.1853 23.5499 18.58 23.3362 19.2805C22.3627 22.4726 19.4637 24.6174 16.1226 24.6174C13.6583 24.6174 11.4356 23.4497 10.0475 21.5564C10.5987 21.6361 11.1625 21.3611 11.4241 20.8352C11.7502 20.1796 11.4831 19.3836 10.8275 19.0575L8.12809 17.7147C7.66515 17.4844 7.136 17.4724 6.67598 17.6815C6.21622 17.8908 5.87766 18.2977 5.74709 18.7979L5.05503 21.4496C4.87019 22.158 5.29467 22.8823 6.00327 23.0673Z' fill='%23EC7D0D'/%3E%3C/svg%3E ");
        // width: 70px;
        // height: 70px;
        // background-image: url("data:image/svg+xml,%3Csvg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35 5C18.4315 5 5 18.4315 5 35C5 51.5685 18.4315 65 35 65C51.5685 65 65 51.5685 65 35C65 26.9923 61.8626 19.7174 56.75 14.3374' stroke='%23FF9B00' stroke-width='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        animation-name: rotation;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @keyframes rotation {
            0% {
                transform:rotate(0deg);
            }
            100% {
                transform:rotate(360deg);
            }
        }
    }

    &-text {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
    }
}
