@import '../../styles/variables.scss';

.header {
  padding: 24px 32px;
  display: flex;
  align-items: center;
  background: $nav-bg;
  border-top: 1px solid $stroke2;
  border-right: 1px solid $stroke2;
  border-bottom: 1px solid $stroke2;
  border-top-right-radius: 8px;

  &-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }

  &-controls {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 16px;

    &-dashboard,
    &-settings,
    &-logout {
      background-size: contain;
      background-repeat: no-repeat;
    }

    &-dashboard {
      width: 14px;
      height: 20px;
      margin-right: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='20' viewBox='0 0 14 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.1435 8.50541V5.33814C12.1517 3.90502 11.596 2.52703 10.6039 1.52215C9.64481 0.538478 8.3893 0 7.05969 0C7.03911 0 7.01441 0 6.99383 0C4.1576 0.00423998 1.8524 2.39559 1.8524 5.33814V8.50541C0.782123 8.63685 0 9.56116 0 10.6805V17.7952C0 19.0036 0.938547 20 2.11173 20H11.8883C13.0615 20 14 19.0036 14 17.7952V10.6805C13.9959 9.5654 13.2138 8.63685 12.1435 8.50541ZM2.67157 5.33814H2.67568C2.67568 2.86199 4.61453 0.835277 6.99794 0.835277H7.00206C8.13408 0.831037 9.22082 1.29319 10.0235 2.11575C10.8592 2.96799 11.3243 4.12974 11.3161 5.33814V8.50965H10.4105V5.33814C10.4187 4.37566 10.0482 3.45135 9.38548 2.77295C8.75978 2.12847 7.91179 1.76383 7.02676 1.76383H7.00206C5.1085 1.76383 3.57718 3.36655 3.57718 5.3339V8.50965H2.67157V5.33814ZM9.5913 5.33814V8.50965H4.40459V5.33814C4.40459 3.83718 5.56542 2.61607 7.00618 2.61607H7.03087C7.69774 2.61607 8.3399 2.89167 8.81329 3.37927C9.3155 3.89654 9.59953 4.60462 9.5913 5.33814ZM13.2138 17.8079C13.2138 18.5499 12.6292 19.152 11.9089 19.152H2.1282C1.40782 19.152 0.823287 18.5499 0.823287 17.8079V10.7017C0.823287 9.95972 1.40782 9.35764 2.1282 9.35764H11.9089C12.6292 9.35764 13.2138 9.95972 13.2138 10.7017V17.8079Z' fill='%23141C24'/%3E%3Cpath d='M8.4245 13.9924C8.24338 13.3352 7.66296 12.8857 7.00022 12.8857C6.18105 12.8857 5.51419 13.5684 5.51419 14.4164C5.51419 15.099 5.95053 15.6969 6.58858 15.8834V17.0706C6.58858 17.3038 6.77382 17.4946 7.00022 17.4946C7.22662 17.4946 7.41186 17.3038 7.41186 17.0706V15.8834C8.1981 15.6502 8.65502 14.8022 8.4245 13.9924ZM7.00022 15.0948C6.63386 15.0948 6.33748 14.7895 6.33748 14.4121C6.33748 14.0348 6.63386 13.7295 7.00022 13.7295C7.36658 13.7295 7.66296 14.0348 7.66296 14.4121C7.66296 14.7895 7.36658 15.0948 7.00022 15.0948Z' fill='%23141C24'/%3E%3C/svg%3E");
    }

    &-logout {
      display: none;
      width: 24px;
      height: 24px;
      background: url('../../icons/logout.svg') no-repeat center;

      @media (min-width: 900px) {
        display: block;
      }
    }
  }
}
