@import '../../styles/variables.scss';

.navbar {
  display: none;
  padding-top: 24px;
  background: $nav-bg;
  border: 1px solid $stroke1;

  @media (min-width: 900px) {
    display: block;
    flex: 0 1 280px;
  }

  .logo {
    margin: 0 24px;
  }
}

.menu {
  padding: 35px 8px;
  background: $nav-bg;

  &-item,
  &-item-child,
  &-item-active {
    color: $text-color;
    text-decoration: none;
  }

  &-item,
  &-item-child,
  &-item-active,
  .MuiTreeItem-label {
    display: flex;
    align-items: center;
    font-family: 'Roboto-500';
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;

    img {
      margin-right: 16px;
    }
  }

  &-item-child {
    padding-left: 42px;
    font-weight: 400;
  }
}

.MuiTreeItem-iconContainer {
  order: 2;
}

.MuiSvgIcon-root {
  transform: rotate(90deg);
}

.MuiCollapse-root {
  padding-left: 0;

  a {
    flex-basis: 100%;
    font-family: 'Roboto-400';
    font-weight: 400;
  }
}

.MuiTreeItem-content {
  gap: 32px;
  padding: 12px 8px 12px 16px;
  border-radius: 8px;

  &:hover {
    background: $neutral-gray-100;
  }

  &-child {
    padding-top: 8px;
    padding-left: 28px;
    padding-bottom: 8px;
    border-radius: 8px;

    &:hover {
      background: $neutral-gray-100;
    }
  }

  &.Mui-selected {
    background: $nav-selected;
  }

  &.Mui-focused {
    background: $neutral-gray-100;
  }

  &.Mui-expanded {
    & .MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }
}

.mobile-logout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding: 7px 12px;
  border-radius: 8px;
  border: 1px solid $stroke2;

  @media (min-width: 900px) {
    display: none;
  }
}
