.notification-success,
.notification-error {
    position: fixed;
    bottom: 32px;
    left: 0;
    right: 0;
    max-width: 471px;
    width: 100%;
    margin: auto;
    padding: 24px 60px;

    border-radius: 2px;
    box-sizing: border-box;

    &-text {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #656565;

        &::before {
            width: 20px;
            height: 20px;
        }
    }
}

.notification-success {
    background: #D2E0FD;

    &-text {
        &::before {
            content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.0717 2.92881C13.1664 -0.976054 6.83455 -0.976489 2.92881 2.92881C-0.976489 6.83412 -0.976054 13.166 2.92881 17.0717C6.83412 20.9762 13.166 20.9766 17.0717 17.0717C20.9766 13.166 20.9762 6.83455 17.0717 2.92881ZM11.3042 14.3482C11.3042 15.0686 10.7203 15.6526 9.99984 15.6526C9.27939 15.6526 8.69546 15.0686 8.69546 14.3482V9.13069C8.69546 8.41024 9.27939 7.82631 9.99984 7.82631C10.7203 7.82631 11.3042 8.41024 11.3042 9.13069V14.3482ZM9.97679 6.90107C9.22547 6.90107 8.72459 6.36889 8.74024 5.71192C8.72459 5.02321 9.22547 4.50711 9.99201 4.50711C10.759 4.50711 11.2442 5.02364 11.2603 5.71192C11.2599 6.36889 10.7594 6.90107 9.97679 6.90107Z' fill='%234380FF'/%3E%3C/svg%3E%0A");
        }
    }
}

.notification-error {
    background: #F3E1DC;

    &-text {
        &::before {
            content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.0745 2.92563C13.1738 -0.975017 6.82666 -0.975402 2.92563 2.92563C-0.975402 6.82666 -0.975017 13.1734 2.92563 17.0745C6.82628 20.9755 13.1734 20.9755 17.0745 17.0745C20.9751 13.1734 20.9751 6.82627 17.0745 2.92563ZM13.9453 13.9453C13.6448 14.2459 13.1577 14.2459 12.8571 13.9453L10 11.0882L7.00713 14.0812C6.7066 14.3817 6.21946 14.3817 5.91893 14.0812C5.61841 13.7806 5.61841 13.2935 5.91893 12.993L8.91185 10L6.05477 7.14296C5.75424 6.84244 5.75424 6.3549 6.05477 6.05476C6.35529 5.75424 6.84244 5.75424 7.14296 6.05476L10 8.91185L12.7209 6.19098C13.0214 5.89046 13.5086 5.89046 13.8091 6.19098C14.1096 6.4915 14.1096 6.97865 13.8091 7.27918L11.0882 10L13.9453 12.8571C14.2459 13.1577 14.2459 13.6448 13.9453 13.9453Z' fill='%23EA8D7D'/%3E%3C/svg%3E ");
        }
    }
}
