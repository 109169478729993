@import '../../styles/variables.scss';

.MuiTooltip-arrow {
  color: #fff;
}

.MuiTooltip-tooltip {
  padding: 12px;
  font-size: 12px;
  line-height: 16px;
  color: $Gray-1;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);

  h3,
  p {
    margin: 0;
  }
}
