$text-color: #141c24;
$nav-bg: #f6f9ff;
$nav-selected: #cadcfd;
$primary: #1c66f6;
$primary100: #e1effe;
$primary700: #0052cc;
$neutral-gray-100: #f2f4f7;
$neutral-gray-200: #e4e7ec;
$neutral-gray-500: #637083;
$stroke1: #eceff5;
$stroke2: #e4edfe;
$widget-bg: #fafcff;
$Neutral-Gray-300: #ced2da;
$Gray-1: #333;
$Gradient-Success: linear-gradient(80deg, #40bf7f 0%, #7ad65c 100%);
$warning: #f64c4c;
$white: #fff;
$orange: #ec7d0d;
$success100: #d9f2e5;
$success: #309161;
$error50: #fee7e7;
$error500: #f64c4c;
