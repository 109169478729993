.users {
  &-header {
    display: flex;
    gap: 32px;
    margin-bottom: 64px;
  }

  &-control {
    display: flex;
    padding-bottom: 16px;

    @media (min-width: 900px) {
      align-items: flex-end;
      justify-content: space-between;
    }

    &-filter {
      display: flex;
      gap: 10px;

      @media (min-width: 900px) {
        margin-left: 70px;
      }
    }
  }

  &-list {
    max-width: 1230px;
    margin: auto;
    border: 1px solid #bbb9b9;

    &-header {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      padding: 24px;
      border-left: 1px solid #bbb9b9;
      border-right: 1px solid #bbb9b9;

      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #a6a6a6;
        user-select: none;
      }
    }

    &-items {
      position: relative;
      max-width: 1230px;
      margin: auto;

      a {
        color: #333;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      padding: 16px 12px;
      padding: 24px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid #bbb9b9;
      }

      &:hover {
        background: #f5f4f8;
      }

      a {
        color: #f2994a;

        &:hover {
          text-decoration: underline;
        }
      }

      li {
        font-size: 14px;
        line-height: 20px;
        user-select: none;
      }
    }

    &-empty {
      padding: 24px;
    }
  }
}
