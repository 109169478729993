@import '../../styles/variables.scss';

.MuiInputBase-root {
  padding: 11.5px 8px;
  line-height: 24px;
  border: 1px solid $Neutral-Gray-300;
  border-radius: 8px;
  background: #fff;

  label + & {
    margin-top: 20px;
  }
}

.MuiInputBase-input {
  padding: 0;
}

.MuiInputBase-sizeSmall {
  padding: 8px 12px;
}
