@import '../../styles/variables.scss';

.MuiTableCell-head {
  color: $neutral-gray-500;
  text-transform: uppercase;
  background: $nav-bg;

  svg {
    margin-left: 8px;
    vertical-align: middle;
  }
}

.MuiTableCell-body {
  font-family: 'Roboto-600';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: $text-color;
}
