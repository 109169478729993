@import '../../styles/variables.scss';

.MuiButton-root {
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  text-transform: none;

  &.Mui-disabled {
    background-color: #cce0ff;
    border: 1px solid #cce0ff;
    color: #fff;
  }
}

.MuiButton-outlined {
  border-color: $primary;
  color: $primary;
}

.MuiButton-contained {
  background-color: $primary;
  color: #fff;

  &:hover {
    background: $primary700;
  }
}

.MuiButton-sizeLarge {
  padding: 11px 24px;
  font-weight: 500;
  line-height: 24px;
}

.MuiButton-sizeSmall {
  padding: 10px 32px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
