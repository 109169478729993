@import '../../styles/variables.scss';

.MuiToolbar-root {
  padding: 8px 0 !important;
  background: $nav-bg;

  @media (min-width: 900px) {
    display: none;
  }
}

.MuiIconButton-root {
  margin-left: auto;
  padding: 8px;
  border: 1px solid $Neutral-Gray-300;
  border-radius: 8px;
}

.MuiDrawer-root {
  z-index: 10;
}

.MuiPaper-root {
  top: 55px;
}
