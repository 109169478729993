@import '../../../styles/variables.scss';

.go-back {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 32px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: $text-color;
  }

  .start-icon {
    font-size: 16px;
  }

  &-left,
  &-right {
    display: block;
    width: 30px;
    height: 30px;
    border: 1px solid #bbb9b9;
    border-radius: 4px;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      border: 1px solid #f2994a;
      background-color: #f2994a;
      transition: 0.1s;
    }
  }

  &-left {
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.000609875 6.58254C-0.000609875 6.36845 0.0792098 6.15439 0.238514 5.99117L5.254 0.854913C5.57305 0.52818 6.09033 0.52818 6.40925 0.854913C6.72817 1.18151 6.72817 1.71115 6.40925 2.03791L1.97127 6.58254L6.40909 11.1272C6.72801 11.4539 6.72801 11.9835 6.40909 12.3101C6.09018 12.637 5.5729 12.637 5.25385 12.3101L0.238359 7.17391C0.0790291 7.0106 -0.000609875 6.79655 -0.000609875 6.58254Z' fill='%23B0B0B0'/%3E%3C/svg%3E%0A");

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.000609875 6.58254C-0.000609875 6.36845 0.0792098 6.15439 0.238514 5.99117L5.254 0.854913C5.57305 0.52818 6.09033 0.52818 6.40925 0.854913C6.72817 1.18151 6.72817 1.71115 6.40925 2.03791L1.97127 6.58254L6.40909 11.1272C6.72801 11.4539 6.72801 11.9835 6.40909 12.3101C6.09018 12.637 5.5729 12.637 5.25385 12.3101L0.238359 7.17391C0.0790291 7.0106 -0.000609875 6.79655 -0.000609875 6.58254Z' fill='%23FFF'/%3E%3C/svg%3E%0A");
    }
  }

  &-right {
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.64905 6.58293C6.64905 6.79702 6.56923 7.01108 6.40992 7.17431L1.39443 12.3107C1.07539 12.6374 0.558107 12.6374 0.239189 12.3107C-0.0797296 11.9841 -0.0797296 11.4544 0.239189 11.1276L4.67717 6.58293L0.239344 2.03818C-0.0795745 1.71144 -0.0795745 1.18185 0.239344 0.855265C0.558262 0.528366 1.07554 0.528366 1.39459 0.855265L6.41008 5.99155C6.56941 6.15485 6.64905 6.36892 6.64905 6.58293Z' fill='%23B0B0B0'/%3E%3C/svg%3E%0A");

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.64905 6.58293C6.64905 6.79702 6.56923 7.01108 6.40992 7.17431L1.39443 12.3107C1.07539 12.6374 0.558107 12.6374 0.239189 12.3107C-0.0797296 11.9841 -0.0797296 11.4544 0.239189 11.1276L4.67717 6.58293L0.239344 2.03818C-0.0795745 1.71144 -0.0795745 1.18185 0.239344 0.855265C0.558262 0.528366 1.07554 0.528366 1.39459 0.855265L6.41008 5.99155C6.56941 6.15485 6.64905 6.36892 6.64905 6.58293Z' fill='%23FFF'/%3E%3C/svg%3E%0A");
    }
  }
}
