@import '../../../styles/variables.scss';

.paginator {
  display: flex;
  border: 1px solid $Neutral-Gray-300;
  border-radius: 4px;

  button {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    border-top: none;
    border-left: 0;
    border-right: 1px solid $Neutral-Gray-300;
    border-bottom: none;
    background: transparent;
    color: $neutral-gray-500;
    cursor: pointer;
  }

  .previous,
  .next {
    width: 44px;
    border-left: none;
    border-right: none;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      transition: 0.1s;
    }
  }

  .previous {
    border-right: 1px solid $Neutral-Gray-300;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.707 5.79303C12.8945 5.98056 12.9998 6.23487 12.9998 6.50003C12.9998 6.76519 12.8945 7.0195 12.707 7.20703L9.41403 10.5L12.707 13.793C12.8892 13.9816 12.99 14.2342 12.9877 14.4964C12.9854 14.7586 12.8803 15.0094 12.6948 15.1948C12.5094 15.3803 12.2586 15.4854 11.9964 15.4877C11.7342 15.49 11.4816 15.3892 11.293 15.207L7.29303 11.207C7.10556 11.0195 7.00024 10.7652 7.00024 10.5C7.00024 10.2349 7.10556 9.98056 7.29303 9.79303L11.293 5.79303C11.4806 5.60556 11.7349 5.50024 12 5.50024C12.2652 5.50024 12.5195 5.60556 12.707 5.79303Z' fill='%23637083'/%3E%3C/svg%3E%0A");
  }

  .next {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.29303 15.2069C7.10556 15.0194 7.00024 14.7651 7.00024 14.4999C7.00024 14.2348 7.10556 13.9804 7.29303 13.7929L10.586 10.4999L7.29303 7.20692C7.11087 7.01832 7.01008 6.76571 7.01236 6.50352C7.01463 6.24132 7.1198 5.99051 7.30521 5.8051C7.49062 5.61969 7.74143 5.51452 8.00363 5.51224C8.26583 5.50997 8.51843 5.61076 8.70703 5.79292L12.707 9.79292C12.8945 9.98045 12.9998 10.2348 12.9998 10.4999C12.9998 10.7651 12.8945 11.0194 12.707 11.2069L8.70703 15.2069C8.5195 15.3944 8.26519 15.4997 8.00003 15.4997C7.73487 15.4997 7.48056 15.3944 7.29303 15.2069Z' fill='%23637083'/%3E%3C/svg%3E%0A");
  }

  .previous.disabled,
  .next.disabled {
    opacity: 0.5;
    cursor: no-drop;

    &:hover {
      background-color: #fff;
    }
  }

  .previous.disabled {
    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.000609875 6.58254C-0.000609875 6.36845 0.0792098 6.15439 0.238514 5.99117L5.254 0.854913C5.57305 0.52818 6.09033 0.52818 6.40925 0.854913C6.72817 1.18151 6.72817 1.71115 6.40925 2.03791L1.97127 6.58254L6.40909 11.1272C6.72801 11.4539 6.72801 11.9835 6.40909 12.3101C6.09018 12.637 5.5729 12.637 5.25385 12.3101L0.238359 7.17391C0.0790291 7.0106 -0.000609875 6.79655 -0.000609875 6.58254Z' fill='%23B0B0B0'/%3E%3C/svg%3E%0A");
    }
  }

  .next.disabled {
    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.64905 6.58293C6.64905 6.79702 6.56923 7.01108 6.40992 7.17431L1.39443 12.3107C1.07539 12.6374 0.558107 12.6374 0.239189 12.3107C-0.0797296 11.9841 -0.0797296 11.4544 0.239189 11.1276L4.67717 6.58293L0.239344 2.03818C-0.0795745 1.71144 -0.0795745 1.18185 0.239344 0.855265C0.558262 0.528366 1.07554 0.528366 1.39459 0.855265L6.41008 5.99155C6.56941 6.15485 6.64905 6.36892 6.64905 6.58293Z' fill='%23B0B0B0'/%3E%3C/svg%3E%0A");
    }
  }

  .active {
    background: $primary100;
    color: $primary;
  }
}
