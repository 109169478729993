@import '../../styles/variables.scss';

.MuiPaper-root {
  background: $widget-bg;
  border-color: $Neutral-Gray-300;
}

.primary {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid $stroke2;
}

.secondary {
  padding: 14px;
  border-radius: 8px;
  border: 1px solid $Neutral-Gray-300;
}
