@import './variables.scss';

.MuiAlert-root {
  width: 100%;
  margin-top: 24px;
  padding: 12px;
  border-radius: 10px;
}

.MuiAlert-colorError {
  background-color: $error50;
}

.MuiAlert-colorSuccess {
  background-color: $success100;
}
