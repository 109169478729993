.domains {
  display: block;

  &-header {
    padding-bottom: 64px;

    h3 {
      margin: 0;
    }

    .MuiPaper-root {
      display: flex;
      flex-direction: column;
      height: 100%;

      form {
        flex: 1 0 100%;
      }
    }
  }

  &-add-form,
  &-configurate-ad {
    &-controls {
      margin-top: auto;

      a {
        display: block;
        text-align: center;
      }

      a,
      button,
      .MuiFormControl-root {
        width: 100%;
      }
    }
  }

  &-configurate-ad {
    p {
      margin: 0;
      padding: 24px 0 0;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &-list {
    max-width: 1230px;
    margin: auto;
    border: 1px solid #bbb9b9;

    &-header {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 24px;
      border-top: 1px solid #bbb9b9;
      border-left: 1px solid #bbb9b9;
      border-right: 1px solid #bbb9b9;

      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #a6a6a6;
        user-select: none;
      }
    }

    &-items {
      position: relative;
      max-width: 1230px;
      margin: auto;

      a {
        color: #333;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 16px 12px;
      padding: 24px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid #bbb9b9;
      }

      &:hover {
        background: #f5f4f8;
      }

      a {
        color: #f2994a;

        &:hover {
          text-decoration: underline;
        }
      }

      li {
        font-size: 14px;
        line-height: 20px;
        user-select: none;
      }

      &-controls {
        display: flex;
        align-items: center;
        gap: 44px;
        justify-content: flex-end;
      }

      &-edit,
      &-remove {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: transparent;
        background-repeat: no-repeat;
        border: 0;
      }

      &-edit {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.512 3.24785L14.752 0.487875C14.1018 -0.16241 13.0492 -0.162515 12.3988 0.487875C11.9359 0.950848 1.75919 11.1276 1.56509 11.3217C1.48838 11.3984 1.43414 11.4946 1.4083 11.5999L0.0174091 17.2642C-0.0893253 17.699 0.305198 18.0883 0.735686 17.9825C1.02963 17.9104 6.13983 16.6555 6.39996 16.5916C6.50528 16.5658 6.60154 16.5115 6.67822 16.4348C6.88269 16.2304 17.0028 6.11024 17.512 5.60104C18.1623 4.95075 18.1624 3.89821 17.512 3.24785ZM1.4045 16.5954L1.46117 16.3647L1.63523 16.5387L1.4045 16.5954ZM2.98361 16.2076L1.79227 15.0163L2.31033 12.9066L5.0933 15.6896L2.98361 16.2076ZM6.25835 15.1752L2.82471 11.7416L11.6913 2.87495L15.1249 6.30859L6.25835 15.1752ZM16.6723 4.76129L15.9864 5.44709L12.5528 2.01345L13.2386 1.32765C13.4247 1.14157 13.7261 1.1414 13.9123 1.32765L16.6723 4.08766C16.8584 4.27382 16.8584 4.57507 16.6723 4.76129Z' fill='%23C1C1C1'/%3E%3C/svg%3E%0A");

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.512 3.24785L14.752 0.487875C14.1018 -0.16241 13.0492 -0.162515 12.3988 0.487875C11.9359 0.950848 1.75919 11.1276 1.56509 11.3217C1.48838 11.3984 1.43414 11.4946 1.4083 11.5999L0.0174091 17.2642C-0.0893253 17.699 0.305198 18.0883 0.735686 17.9825C1.02963 17.9104 6.13983 16.6555 6.39996 16.5916C6.50528 16.5658 6.60154 16.5115 6.67822 16.4348C6.88269 16.2304 17.0028 6.11024 17.512 5.60104C18.1623 4.95075 18.1624 3.89821 17.512 3.24785ZM1.4045 16.5954L1.46117 16.3647L1.63523 16.5387L1.4045 16.5954ZM2.98361 16.2076L1.79227 15.0163L2.31033 12.9066L5.0933 15.6896L2.98361 16.2076ZM6.25835 15.1752L2.82471 11.7416L11.6913 2.87495L15.1249 6.30859L6.25835 15.1752ZM16.6723 4.76129L15.9864 5.44709L12.5528 2.01345L13.2386 1.32765C13.4247 1.14157 13.7261 1.1414 13.9123 1.32765L16.6723 4.08766C16.8584 4.27382 16.8584 4.57507 16.6723 4.76129Z' fill='%23F9884B'/%3E%3C/svg%3E%0A");
        }
      }

      &-remove {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.11133 3.20237H5.0225V3.40731V3.61228H3.49579H2.94987L2.99772 4.1561L4.09558 16.6339L4.13571 17.0901H4.59365H13.3831H13.841L13.8812 16.6339L14.9793 4.15611L15.0271 3.61228H14.4812H12.9781V3.40731V3.20237H16.8894V3.61228H15.8965H15.4386L15.3984 4.06844L14.2329 17.3128L14.2328 17.3132C14.2237 17.4187 14.1351 17.5 14.0288 17.5H3.94811C3.84189 17.5 3.75334 17.4189 3.74406 17.3131L3.74405 17.313L2.57855 4.06845L2.53841 3.61228H2.08047H1.11133V3.20237ZM10.5682 3.61228H7.4324V3.40731V3.20237H10.5682V3.40731V3.61228ZM11.5682 2.20237V1.40991V0.909906H11.0682H6.9324H6.4324V1.40991V2.20237H6.0225V1.17492C6.0225 0.803204 6.3257 0.5 6.69742 0.5H11.3032C11.6749 0.5 11.9781 0.803205 11.9781 1.17492V2.20237H11.5682ZM11.2342 14.015L11.5031 6.67234L11.9127 6.68737L11.6438 14.0299L11.2342 14.015ZM8.79532 14.0222V6.6801H9.20522V14.0222H8.79532ZM6.49737 6.67216L6.76625 14.0149L6.35662 14.0299L6.08771 6.6872L6.49737 6.67216Z' stroke='%23C1C1C1'/%3E%3C/svg%3E ");
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.11133 3.20237H5.0225V3.40731V3.61228H3.49579H2.94987L2.99772 4.1561L4.09558 16.6339L4.13571 17.0901H4.59365H13.3831H13.841L13.8812 16.6339L14.9793 4.15611L15.0271 3.61228H14.4812H12.9781V3.40731V3.20237H16.8894V3.61228H15.8965H15.4386L15.3984 4.06844L14.2329 17.3128L14.2328 17.3132C14.2237 17.4187 14.1351 17.5 14.0288 17.5H3.94811C3.84189 17.5 3.75334 17.4189 3.74406 17.3131L3.74405 17.313L2.57855 4.06845L2.53841 3.61228H2.08047H1.11133V3.20237ZM10.5682 3.61228H7.4324V3.40731V3.20237H10.5682V3.40731V3.61228ZM11.5682 2.20237V1.40991V0.909906H11.0682H6.9324H6.4324V1.40991V2.20237H6.0225V1.17492C6.0225 0.803204 6.3257 0.5 6.69742 0.5H11.3032C11.6749 0.5 11.9781 0.803205 11.9781 1.17492V2.20237H11.5682ZM11.2342 14.015L11.5031 6.67234L11.9127 6.68737L11.6438 14.0299L11.2342 14.015ZM8.79532 14.0222V6.6801H9.20522V14.0222H8.79532ZM6.49737 6.67216L6.76625 14.0149L6.35662 14.0299L6.08771 6.6872L6.49737 6.67216Z' stroke='%23F9884B'/%3E%3C/svg%3E ");
        }
      }
    }

    &-empty {
      padding: 24px;
    }

    &-remove {
      &-controls {
        button {
          flex-basis: 47%;
        }
      }

      &-text {
        margin: 16px 0 40px;
      }
    }
  }

  &-update-form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 24px 0 0;

    &-controls {
      display: flex;
      gap: 16px;
    }
  }
}

.MuiDialog-container {
  & .MuiPaper-root {
    width: 460px;
    max-width: 460px;
  }
}
